/*
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * ALPHA Constants --[DO NOT CHANGE]
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
export const HOME_URL = "https://alpha.ucu.ac.ug";
export const API_BASE_URL = `${HOME_URL}/alpha-api`;
export const STUDENT_URL = `${HOME_URL}/students`;
export const STAFF_UI_URL = `${HOME_URL}/staff`;
export const APPLY_URL = "https://apply.ucu.ac.ug";
export const API_APPLICATION_ADMISSION_URL = `${HOME_URL}/application-api`;
export const API_ERROR_HANDLER_URL = `${HOME_URL}/error-handler-service`;

/*
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * MIS-FORMER Constants --[DO NOT CHANGE]
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
// export const HOME_URL = "https://mis-former.ucu.ac.ug";
// export const API_BASE_URL = `${HOME_URL}/alpha-api`;
// export const STUDENT_URL = `${HOME_URL}/students`;
// export const STAFF_UI_URL = `${HOME_URL}/staff`;
// export const APPLY_URL = "https://apply.ucu.ac.ug";
// export const API_APPLICATION_ADMISSION_URL = `${HOME_URL}/application-api`;
// export const API_ERROR_HANDLER_URL = `${HOME_URL}/error-handler-service`;

/*
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * ALPHA-TEST Constants --[DO NOT CHANGE]
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
// export const HOME_URL = "https://alpha-test.ucu.ac.ug";
// export const API_BASE_URL = `${HOME_URL}/alpha-api`;
// export const STUDENT_URL = `${HOME_URL}/students`;
// export const STAFF_UI_URL = `${HOME_URL}/staff`;
// export const APPLY_URL = "https://apply.ucu.ac.ug";
// export const API_APPLICATION_ADMISSION_URL = `${HOME_URL}/application-api`;
// export const API_ERROR_HANDLER_URL = `${HOME_URL}/error-handler-service`;


/*
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Development Constants
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
// export const HOME_URL = "http://localhost:3004";
// export const STUDENT_URL = `http://localhost:3005`;
// export const API_BASE_URL = `http://localhost:5000/api`;
// export const STAFF_UI_URL = `http://localhost:3000`;
// export const APPLY_URL = "https://apply.ucu.ac.ug";

/*
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * DO NOT CHANGE THE LINKS BELOW
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

export const API_ECHAGUA_BASE_URL =
  "https://echagua.ucu.ac.uqg:8443/mis-solution/api";

export const API_BI_BASE_URL = "http://10.4.2.244:5030/api";

export const BI_BASE_URL = "http://10.4.2.79:3000";

export const UCU_LIBRARY = "http://catalogue.library.ucu.ac.ug/";

export const ACCESS_TOKEN = "accessToken";

export const USER_TYPE = "type";

export const POLL_LIST_SIZE = 30;

export const MAX_CHOICES = 6;

export const POLL_QUESTION_MAX_LENGTH = 140;

export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

export const FluidFont = localStorage.getItem("Fluid_Font")
  ? localStorage.getItem("Fluid_Font")
  : 40;

export const USER_TYPE_ENUM = {
  STAFF: 0,
  STUDENT: 1,
  PROSPECTIVE_APPLICANT: 2,
  JOB_APPLICANT: 3,
  STUDENT_SPONSOR: 4,
  STUDENT_ALUMNI: 5,
  CAFETERIA: 7,
};

export const RESIDENCE_TYPE_ENUM = {
  HALL: 1,
  AFFLIATE_HOSTEL: 2,
};

export const MARITAL_STATUS = {
  SINGLE: 1,
  MARRIED: 2,
};

export const ACCOUNT_STATUS = {
  ACTIVE: 1,
  DEACTIVATED: 0,
};

export const USER_TITLES = [
  { name: "Mr.", value: "Mr." },
  { name: "Dr.", value: "Dr." },
  { name: "Miss", value: "Miss" },
  { name: "Prof.", value: "Prof." },
  { name: "Mrs.", value: "Mrs." },
  { name: "Rev.", value: "Rev." },
];

export const ACCEPTEDFILETYPES = [
  { name: ".png", value: "image/png" },
  { name: ".jpg", value: "image/jpg" },
  { name: ".jpeg", value: "image/jpeg" },
  { name: ".doc", value: "application/doc" },
  { name: ".pdf", value: "application/pdf" },
  { name: ".txt", value: "application/txt" },
  { name: ".odf", value: "application/odf" },
  { name: ".docx", value: "application/docx" },
];

export const DAYMILLISECONDS = 86400000;

export const SECRETEKEY = "MISdatapass20@)}";

export const REFRESH_TOKEN = "refreshToken";
