// @mui material components
import Card from "@mui/material/Card";
import { useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

// Images
import bgImage from "assets/img/loginBg3.png";
import nobg from "assets/img/uculogotousenobg.png";
import { Grid } from "@mui/material";

import { changePasswordNew } from "util/APIUtils";

import {
  osName,
  osVersion,
  browserVersion,
  browserName,
} from "react-device-detect";
import { verifyLength } from "constants/methodConstants";
import { useSnackbar } from "notistack";

const TEST_SITE_KEY = "6Le5Tr4aAAAAAF3vZQWQEhrJ8mljqMm-svtlMXTb";
function Cover() {
  const [option, setoption] = useState(true);
  const [notRobot, setnotRobot] = useState(false);
  const [changeKey, setchangeKey] = useState(Math.random());

  const [registerEmail, setregisterEmail] = useState("");

  const [mailState, setmailState] = useState(false);

  // const [msg, setmsg] = useState("");
  // const [show, setshow] = useState(false);
  // const [colorSnack, setcolorSnack] = useState("");

  const [showResponse, setshowResponse] = useState(false);
  const [responseMesaage, setresponseMesaage] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  function openSnackbar(message, color) {
    enqueueSnackbar(message, { variant: color });
  }

  const handleChangeRoboot = (response) => {
    if (response) {
      setnotRobot(true);
    }
  };
  const handleMyChange = () => {
    setnotRobot(false);
  };

  const expiredCallback = () => {
    setnotRobot(false);
  };

  const handleFixedNavbar = async (event) => {
    await setoption(event.target.value === "true");
    setchangeKey(Math.random());
    //  alert(event.target.value)
  };

  function handlePasswordResetNew() {
    const Reques = {
      email: registerEmail,
      showRocover: option,
      osName: `${osName}-${osVersion}`,
      browserName: `${browserName}-${browserVersion}`,
    };

    // alert(JSON.stringify(Reques))
    if (registerEmail === "" || mailState) {
      // openSnackbar({ message: "Type Correct Email", type: false });
      openSnackbar("Type Correct Email", "error");

      setmailState(true);
    } else {
      changePasswordNew(Reques)
        .then((response) => {
          openSnackbar(response.message, "success");

          if (response.success === false) {
            openSnackbar(response.message, "error");

            // setshowResponse(true)
            setresponseMesaage(response.message);
          } else {
            setregisterEmail("");
            setshowResponse(true);
            setresponseMesaage(response.message);
          }
        })
        .catch((error) => {
          openSnackbar(error.message, "error");
        });
    }
  }

  function change(event, stateName, type, stateNameEqualTo) {
    setresponseMesaage("");
    switch (type) {
      case "email":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          setmailState(false);
          setregisterEmail(event.target.value);
        } else {
          // alert(stateNameEqualTo)
          setregisterEmail(event.target.value);
          setmailState(true);
        }
        break;
      default:
        break;
    }
  }

  return (
    <CoverLayout
      coverHeight="45vh"
      image={bgImage}

      // style={{marginTop:"-20px"}}
    >
      <Card style={{ marginTop: "-120px", marginBottom: "30px" }}>
        <MDBox
          style={{ borderBottom: "1px solid #CCC" }}
          variant="gradient"
          bgColor="none"
          borderRadius="lg"
          //   coloredShadow="dark"
          mx={2}
          // mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <div
            style={{
              background: "rgba(255,255,255,1)",
              padding: "5px",
              // borderBottom:"4px solid #b5004f"
            }}
          >
            {/* <p style={{color:"#b5004f"}}>*** Happy Holidays ***</p> */}
            <img
              src={nobg}
              // src={first.color === "#000" ? imageToUse : logodark}
              style={{ maxWidth: "100%" }}
              alt="logo"
              // className={classes.loginlogo}
            />
          </div>
          {/* <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography> */}
        </MDBox>

        {!showResponse ? (
          <MDBox pt={4} pb={3} px={2}>
            <MDBox mt={0} mb={1}>
              <FormControl>
                <MDBox mt={0} mb={2}>
                  <FormLabel mb={2} id="demo-row-radio-buttons-group-label">
                    Change Password
                  </FormLabel>
                </MDBox>
                {/* <RadioGroup
                  onChange={handleFixedNavbar}
                  value={option}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Change password"
                  />
                  <FormControlLabel
                    value
                    control={<Radio />}
                    label="Recover password"
                  />
                </RadioGroup> */}
              </FormControl>
            </MDBox>
            <MDBox mt={0} mb={2}>
            <MDTypography
                display="block"
                variant="button"
                my={1}
                key={changeKey}
              >
                Please enter your <span style={{ color: "purple", fontWeight: "bold" }}>username</span> or <span style={{ color: "purple", fontWeight: "bold" }}>email</span> below, such as{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>johndoe@ucu.ac.ug</span> or{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>johndoe</span>, and you will receive
                password recovery instructions in your{" "}
                {!option ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>email.</span>
                ) : (
                  <b style={{ color: "green" }}>Alternative email.</b>
                )}
              </MDTypography>

            </MDBox>

            <MDBox mt={0} mb={2}>
              <MDTypography
                fontSize={20}
                fontWeight="medium"
                type="subtitle-1"
                display="block"
                variant="button"
                my={1}
                t
                key={changeKey}
              >
                {responseMesaage}
              </MDTypography>
            </MDBox>

            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type="text"
                  label="Email or Username"
                  variant="outlined"
                  fullWidth
                  value={registerEmail}
                  onChange={(event) => change(event, mailState, "email", 4)}
                  error={mailState}
                />
              </MDBox>
              <MDBox mt={4} mb={1} alignItems>
                <ReCAPTCHA
                  sitekey={TEST_SITE_KEY}
                  render="explicit"
                  onChange={handleChangeRoboot}
                  onloadCallback={handleMyChange}
                  onExpired={expiredCallback}
                  type="images"
                />
              </MDBox>

              <MDBox mt={6} mb={1} sx={{ backgroundColor: "black" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5} lg={4}>
                    <Link style={{ color: "#CCC", fontSize: "small" }} to="/">
                      <MDButton variant="gradient" color="light">
                        Back To Login Page
                      </MDButton>
                    </Link>
                  </Grid>

                  <Grid item xs={12} md={2} lg={4} />

                  <Grid item xs={12} md={5} lg={4}>
                    {notRobot ? (
                      <MDButton
                        onClick={() => handlePasswordResetNew()}
                        variant="gradient"
                        color="success"
                        style={{ fontSize: "small" }}
                      >
                        Send Request
                      </MDButton>
                    ) : null}
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
        ) : (
          <MDBox pt={4} pb={3} px={3}>
            <MDBox mt={0} mb={2}>
              <FormLabel mb={2} id="demo-row-radio-buttons-group-label">
                Instructions
              </FormLabel>
            </MDBox>
            <MDTypography
              fontSize={20}
              fontWeight="medium"
              type="subtitle-1"
              display="block"
              variant="button"
              my={1}
              t
              key={changeKey}
            >
              {responseMesaage}
            </MDTypography>

            <MDBox mt={6} mb={1} sx={{ backgroundColor: "black" }}>
              <Link
                style={{
                  color: "#CCC",
                  marginBottom: "10px",
                  fontSize: "small",
                }}
                to="/"
              >
                <MDButton variant="gradient" color="light">
                  Back To Login Page
                </MDButton>
              </Link>
            </MDBox>
          </MDBox>
        )}
      </Card>
    </CoverLayout>
  );
}

export default Cover;
