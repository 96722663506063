import React, { useEffect } from "react";
import { ACCESS_TOKEN } from "constants";
import { Routes, Route, useLocation } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { useMaterialUIController } from "context";
import routes from "routes.js";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";

function App({ jwt, reason }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    if (jwt != null && jwt !== "logout") {
      localStorage.setItem(ACCESS_TOKEN, jwt);
    }
    if (localStorage.getItem(ACCESS_TOKEN) === "logout") {
      localStorage.clear();
    }
  }, [jwt]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        const clonedComp= React.cloneElement(route.component, {reason:reason})
        return (
          <Route
            exact
            path={route.route}
            element={clonedComp}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ConfirmProvider
        defaultOptions={{
          confirmationButtonProps: { autoFocus: true },
          cancellationButtonProps: { color: "warning" },
        }}
      >
        <CssBaseline />
        <Routes>{getRoutes(routes)}</Routes>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  jwt: PropTypes.string.isRequired,
};

export default App;
