import { Link } from "react-router-dom";
import { getCurrentUser } from "util/APIUtils";
// testing the file
import MDBox from "components/MDBox";

export function verifyPassword(value) {
  const format = /[ !@#% ]/g;
  if (
    value.length >= 6 &&
    value.replace(/[^A-Z]/g, "").length > 0 &&
    value.replace(/[^a-z]/g, "").length > 0 &&
    /\d/.test(value) &&
    format.test(value)
  ) {
    return true;
  }
  return false;
}

// Common Functions
export function removeCommas(str) {
  let strTemp = str ?? "";
  if (strTemp !== "") {
    while (strTemp.search(",") >= 0) {
      strTemp = `${strTemp}`.replace(",", "");
    }
    return Number(strTemp);
  }
  return "";
}

export function verifyMoney(money) {
  const numberRex = /^[0-9]+$/;
  if (numberRex.test(removeCommas(money))) {
    return true;
  }
  return false;
}

export function formatMoney(n) {
  if (n !== null && n !== undefined) {
    return n
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
}

export function formatEnum(enumValue) {
  if (enumValue !== null && enumValue !== undefined && enumValue !== "") {
    const frags = enumValue.toLowerCase().split("_");
    let i;
    for (i = 0; i < frags.length; i += 1) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }
  return "Unknown";
}

export function formatEnumToUppercase(enumValue) {
  if (enumValue !== null && enumValue !== undefined && enumValue !== "") {
    const frags = enumValue.toUpperCase().split("_");

    return frags.join(" ");
  }
  return "Unknown";
}

// function that returns true if value is an integer, false otherwise
export function verifyNumber(value) {
  const numberRex = /^[0-9]+$/;
  const valueTemp = value ?? null;

  return valueTemp !== null && valueTemp !== "" && numberRex.test(value);
}

// function that verifies if a string has a given length or not
export function verifyLength(value, length) {
  const valueTemp = value ?? null;
  return valueTemp !== null && valueTemp.length >= length;
}

export function validateLength(value, length, maxLength) {
  const valueTemp = value ?? null;
  return (
    valueTemp !== null &&
    valueTemp.length >= length &&
    valueTemp.length <= maxLength
  );
}

export function verifyEmail(value) {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

// verifyPhone
export function verifyPhone(value) {
  const valueTemp = value ?? "";

  // const phoneNo = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  const phoneNo = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

  return (
    valueTemp !== "" &&
    valueTemp.match(phoneNo) &&
    (valueTemp?.replace(/[^0-9]/g, "").length >= 12 ||
      valueTemp?.replace(/[^0-9]/g, "").length <= 20)
  );
}

export function phoneFormat(input) {
  // returns (###) ###-####
  let inputTemp = input?.replace(/\D/g, "") ?? "";
  const size = inputTemp.length;
  if (size > 0 && size <= 3) {
    inputTemp = `(+${inputTemp}`;
  } else if (size > 0 && size <= 6) {
    inputTemp = `(+${inputTemp.slice(0, 3)}) ${inputTemp.slice(3, 6)}`;
  } else if (size > 0) {
    inputTemp = `(+${inputTemp.slice(0, 3)}) ${inputTemp.slice(
      3,
      6
    )} ${inputTemp.slice(6, 9)} ${inputTemp.slice(9)}`;
  }

  return inputTemp;
}

export function renderText(textData) {
  const parser = new DOMParser();
  const el = parser.parseFromString(`<div>${textData}</div>`, "text/html");
  let link = null;
  el.querySelectorAll("a").forEach((a) => {
    const to = a.getAttribute("href");
    const text = a.innerText;
    const linkTo = `staff${to.split("staff")[1]}`;
    const finalLink = `/${linkTo}`;
    link = <Link to={finalLink}>{text}</Link>;
    a.replaceWith("");
  });
  return (
    <MDBox>
      <MDBox dangerouslySetInnerHTML={{ __html: el.body.innerHTML }} /> {link}
    </MDBox>
  );
}

export function numberWithCommas(x) {
  if (x !== null && x !== undefined) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
}

export function showSignature(data) {
  if (data === null) {
    return <MDBox>No Signature Added</MDBox>;
  }
  return (
    <MDBox>
      <img
        id="testImage"
        style={{
          maxHeight: "50px",
          zIndex: -10,
          transform: data.includes("againromainvirgo")
            ? "rotate(0deg)"
            : "rotate(90deg)",
        }}
        src={data}
        alt="logo"
      />
      {/* <canvas id="canvas"></canvas> */}
    </MDBox>
  );
}

export async function permissions(module, submodule, process, permission, fx) {
  let userPermissions = [];
  let permitted = false;

  getCurrentUser()
    .then((response) => {
      userPermissions = response.permissions;

      if (userPermissions.length > 0) {
        userPermissions.map((access) => {
          if (
            access.module === module &&
            access.process === process &&
            access.permission === permission &&
            !permitted
          ) {
            permitted = true;
            fx();
          } else {
            console.log(`2show ${process} ${permitted}`);
          }
          return 0;
        });
      }

      return permitted;
    })
    .catch((error) => {
      console.log("Permissions Error - ", error.message);
      return permitted;
    });
}

export const getInitials = (name) => {
  let initials = "NN";
  if (name !== null && name.toUpperCase() !== "NONE") {
    initials = name.split(" ")[0].charAt(0) + name.split(" ")[1].charAt(0);
  }
  return initials.toUpperCase();
};

export function capitalizeFirstLetter(string) {
  if (string !== null && string !== undefined && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}

export const capitalizeString = (x) =>
  typeof x === "string" && x !== null && x !== undefined ? x.toUpperCase() : x;

export function scrollToTopPage(pageId) {
  document
    .getElementById(pageId)
    ?.scrollIntoView({ behavior: "smooth", block: "start" });
}

export function removeTags(str) {
  if (str === null || str === "") return false;

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export const uniqueArray = (params) =>
  params.reduce((filter, current) => {
    const dk = filter.find((item) => item.label === current.label);
    if (!dk) {
      return filter.concat([current]);
    }
    return filter;
  }, []);

export function isObjectEmpty(obj) {
  if (obj !== undefined && obj !== null) {
    return Object.entries(obj).length <= 0;
  }
  return true;
}

export function isNotEmpty(object, param) {
  return !isObjectEmpty(object) && param !== null && param !== undefined;
}

export function intersectElements(a, b, param) {
  let shortArray = [];
  let longArray = [];
  // indexOf to loop over shorter
  if (b.length > a.length) {
    longArray = b;
    shortArray =
      param !== undefined && param !== null
        ? new Set(a.flatMap((e) => e[param]))
        : new Set(a.flatMap((e) => e));
  } else {
    longArray = a;
    shortArray =
      param !== undefined && param !== null
        ? new Set(b.flatMap((e) => e[param]))
        : new Set(b.flatMap((e) => e));
  }

  return param !== undefined && param !== null
    ? longArray.filter((e) => shortArray.has(e[param]))
    : longArray.filter((e) => shortArray.has(e));
}

export function complementaryElements(a, b) {
  let shortArray;
  let longArray = [];
  // indexOf to loop over shorter
  if (b.length > a.length) {
    longArray = b;
    shortArray = new Set(a.flatMap((e) => e.value));
  } else {
    longArray = a;
    shortArray = new Set(b.flatMap((e) => e.value));
  }
  return longArray.filter((e) => !shortArray.has(e.value));
}

export function hideEmailPartially(email) {
  return email.replace(/(.{2})(.*)(?=@)/, (gp1, gp2, gp3) => {
    let encryptEmail = gp2;
    for (let i = 0; i < gp3.length; i += 1) {
      encryptEmail += "*";
    }
    return encryptEmail;
  });
}

export const isObjectNull = (param) =>
  param === null ||
  param === undefined ||
  JSON.stringify(param) === JSON.stringify({});

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const validatePinCode = (value) =>
  value !== "" && /^[0-9]*$/.test(value);
export const formatTime = (t) =>
  `${String(Math.floor(t / 60)).padStart(2, "0")}:${String(t % 60).padStart(
    2,
    "0"
  )}`;

export function getCurrentQuarter() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  return Math.floor(currentMonth / 3) + 1;
}

export function convertDateStringToDate(dateString) {
  if (dateString === null || dateString === undefined || dateString === "") {
    return new Date();
  }

  // Remove the "th" from the day
  const dString = dateString.replace(/(\d+)(th)/, "$1") ?? "";
  const date = new Date(dString);

  return date;
}

export const stringToHslColor = (str, s, l) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}
