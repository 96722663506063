import React from "react";
// nodejs library that concatenates classes
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle";

function RegularButton({ ...props }) {
  const {
    classes,
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    myColor,
    myShadow,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  return (
    <Button
      style={{ background: myColor, boxShadow: myShadow }}
      {...rest}
      classes={muiClasses}
      className={btnClasses}
    >
      {children}
    </Button>
  );
}

RegularButton.defaultProps = {
  color: "primary",
  size: "md",
  simple: false,
  round: false,
  className: "",
  fullWidth: false,
  disabled: false,
  block: false,
  link: false,
  justIcon: false,
  muiClasses: {},
  myShadow: "none",
  myColor: "none",
};

RegularButton.propTypes = {
  children: PropTypes.node.isRequired,
  myColor: PropTypes.string,
  myShadow: PropTypes.string,
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "transparent",
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
};

export default withStyles(buttonStyle)(RegularButton);
