import { API_BASE_URL, ACCESS_TOKEN } from "constants";

const request = (opt, isAuth) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN) && !isAuth) {
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    );
  }

  const defaults = { headers };
  const options = { ...defaults, ...opt };

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function login(loginRequest) {
  return request(
    {
      url: `${API_BASE_URL}/auth/signin`,
      method: "POST",
      hideToken: true,
      body: JSON.stringify(loginRequest),
    },
    true
  );
}

export function mfaSetup(loginRequest) {
  return request(
    {
      url: `${API_BASE_URL}/auth/mfaSetup`,
      method: "POST",
      body: JSON.stringify(loginRequest),
    },
    true
  );
}

export function doneSettingUpAuthApp(loginRequest) {
  return request(
    {
      url: `${API_BASE_URL}/auth/doneSettingUpAuthApp`,
      method: "POST",
      body: JSON.stringify(loginRequest),
    },
    true
  );
}

export function verifyOTPToken(loginRequest) {
  return request(
    {
      url: `${API_BASE_URL}/auth/verifyOTPToken`,
      method: "POST",
      body: JSON.stringify(loginRequest),
    },
    true
  );
}

export function addToken(dataToSend1) {
  return request({
    url: `${API_BASE_URL}/auth/addToken`,
    method: "POST",
    hideToken: true,
    body: JSON.stringify(dataToSend1),
  });
}

export function checkUserName(loginRequest) {
  return request(
    {
      url: `${API_BASE_URL}/auth/checkUserName`,
      method: "POST",
      hideToken: true,
      body: JSON.stringify(loginRequest),
    },
    true
  );
}

export function changePasswordNew(changePasswordRequest) {
  return request(
    {
      url: `${API_BASE_URL}/auth/changePasswordNew`,
      method: "POST",
      hideToken: true,
      body: JSON.stringify(changePasswordRequest),
    },
    true
  );
}

export function checkToken(changePasswordRequest) {
  return request({
    url: `${API_BASE_URL}/auth/checkTokenNew`,
    method: "POST",
    body: JSON.stringify(changePasswordRequest),
  });
}

export function updatePassword(signInRequest) {
  return request(
    {
      url: `${API_BASE_URL}/auth/updatePassword`,
      hideToken: true,
      method: "POST",
      body: JSON.stringify(signInRequest),
    },
    true
  );
}

export function getCurrentUser() {
  return request({
    url: `${API_BASE_URL}/user/me`,
    method: "GET",
  });
}

export function modifyUser2FA(data) {
  return request({
    url: API_BASE_URL + "/user/modifyUser2FA",
    method: "POST",
    body: JSON.stringify(data),
  });
}
